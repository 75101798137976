// List your mixins in here to keep things tidy

// A basic button
@mixin button($btn-bg, $btn-text) {
  font-weight: $bold-font-weight;
  padding: 2rem;
	line-height: $base-line-height;
	color: $btn-text;
	background-color: $btn-bg;
	border: 3px solid $btn-bg;
}

// Disable text selection
@mixin user-select($argument: none){
  -webkit-user-select: $argument;
  -moz-user-select: $argument;
  -ms-user-select: $argument;
  user-select: $argument;
}
