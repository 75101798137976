// utilities for modifying some default browser behaviour

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, button, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

* {
	-webkit-box-sizing: border-box;
    box-sizing: border-box
}

* :before, * :after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
	outline: none;
}

ul,
ol,
dl {
	list-style: none;
}

em {
	font-style: italic;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

pre {
	background-color: $grey-bg-color;
	border-radius: 0.3rem;
	padding: 1rem;
}

code {
	background-color: $grey-bg-color;
  padding: 0.2rem 0.4rem;
}

::selection {
  background-color: $selection-bg-color;
}

input,
textarea {
	border: 0.05em solid $hairline-color;
	&:focus {
		border: 0.05em solid $text-color;
	}
}

textarea {
  vertical-align: top;
	resize: vertical;
	max-width: 100%;
}

button {
	@include button($link-color, $text-color-negative);
}
