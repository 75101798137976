// Basic Typographic Defaults

// set font sizes in percent on the root element

html {
  font-size: $base-font-size;
  @media screen and (min-width: $screen-xs-min) {
    font-size: $base-font-size * 1.2;
  }
  @media screen and (min-width: $screen-md-min) {
    font-size: $base-font-size * 1.4;
  }
}

// now we can specify a single font size in rem for each of our elements
// the root breakpoints above will then scale all elements together

body {
  color: $text-color;
  font-family: $base-font;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  font-style: normal;
	font-variant: normal;
  font-size: 1.6rem; // this is equivalent to 16px
}

h1,h2,h3,h4,h5,h6,p,figure,time {
  padding-bottom: 2rem;
}

h1,h2,h3,h4,h5,h6,figure {
  p + &,
  figure + & {
    padding-top: 2rem;
  }
}


p.intro {
  font-size: 2.4rem;
  color: lighten($text-color, 40%);
  padding-bottom: 4rem;
  @media screen and (min-width: $screen-sm-min) {
    padding-bottom: 4rem;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-weight: $bold-font-weight;
  line-height: $base-line-height / 1.3;
}

h2 {
  font-size: 3.2rem;
  padding-bottom: 4rem;
}

h3 {
  font-size: 2rem;
}

code {
  font-family: $code-font;
  font-size: 1.2rem;
  color: $text-color;
}

strong {
  font-weight: $bold-font-weight;
}

small {
	font-size: 1.2rem;
}

a,
a:visited {
  border-bottom: 0.05em solid $link-color;
	text-decoration: none;
	color: $link-color;
}

a:hover,
a:active {
  text-decoration: none;
  border: 0;
  color: $link-hover-color;
}

header > h1 {
  line-height: $base-line-height;
  font-weight: $base-font-weight;
  @media screen and (min-width: $screen-sm-min) {
    text-align: left;
  }
}
